<template>
  <div class="container py-5">
  <div class='embed-container'><iframe width='560' height='315' src='https://www.youtube-nocookie.com/embed/C-_bsbWFkO4' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>
    <br>
    <p class="fw-bold">【OBSERV®｜臺灣醫用雷射光電學會 2023秋季年會】</p>
    <p>
      OBSERV®擁有直觀呈現技術，幫助醫師及愛美者搭建最佳的溝通橋樑，追求美麗安心上手！
      <br>
      邀請蒞臨攤位編號“28”，了解更多OBSERV®！
    </p>
    <br>
    <p>
      時間：10/15(日) 08:30-18:00<br>
      地點：華南銀行國際會議中心2樓<br>
      展位：28
    </p><br>



  </div>
</template>
<script>
export default {};
</script>
